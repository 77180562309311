<template>
  <div class="order-detail">
    <div class="ivu-card">
      <div class="card-laft">{{$t('orderDetail.ddxq')}}</div>
    </div>
    <div class="order-card">
      <p class="pay_status">{{orderDetails.order_status}}</p>
      <p class="order_no">{{$t('orderDetail.ddh')}}：{{orderDetails.order_no}}</p>
      <p class="order_no">{{$t('orderDetail.cjsj')}}：{{orderDetails.create_time}}</p>
    </div>
    <div class="order-card">
      <p class="pay_status">{{$t('orderDetail.shrx')}}</p>
      <p class="order_no">{{$t('orderDetail.shr')}}：{{orderDetails.user_name}}</p>
      <p class="order_no">{{$t('orderDetail.sjhm')}}：{{orderDetails.user_phone}}</p>
      <p class="order_no">{{$t('orderDetail.shdz')}}：{{orderDetails.user_address}}</p>
    </div>
    <el-table :data="orderDetails.order_item_dolist"
              style="width: 100%">
      <el-table-column prop="product_icon"
                       :label="$t('orderDetail.cptp')"
                       width="270"
                       align="center">
        <template slot-scope="scope">
          <el-popover placement="right"
                      title=""
                      trigger="hover">
            <img :src="scope.row.product_icon" />
            <img slot="reference"
                 :src="scope.row.product_icon"
                 :alt="scope.row.product_icon"
                 style="max-height: 50px;max-width: 130px">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="product_name"
                       :label="$t('orderDetail.cpmc')"
                       width="180">
      </el-table-column>
      <el-table-column prop="order_no"
                       :label="$t('orderDetail.ddh')"
                       width="180">
      </el-table-column>
      <el-table-column prop="product_price"
                       :label="$t('orderDetail.dj')">
      </el-table-column>
      <el-table-column prop="product_num"
                       :label="$t('orderDetail.sl')">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'orderDetail',
  data () {
    return {
      orderDetails: []
    };
  },

  mounted () {
    this.getDetail()
  },

  methods: {
    async getDetail () {
      let data = {
        order_no: this.$route.query.no,
        is_root: 0
      }
      let res = await this.api.post(this.apiPath.orderList, data);
      this.orderDetails = res.result_list[0]
    }
  },
};
</script>

<style lang="less" scoped>
.order-detail {
  padding: 40px;
  .ivu-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .card-laft {
      border-left: 3px solid #7f0114;
      padding-left: 10px;
      font-size: 15px;
    }
  }
  .order-card {
    padding: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8eaec;
    .pay_status {
      margin-bottom: 5px;
    }
    .order_no {
      font-size: 13px;
    }
  }
}
@media screen and (max-width: 750px) {
  .order-detail {
    padding: 0 15px;
  }
}
</style>