<!-- 所有语言单独页面懒得建 都进这个页面 然后根据路由判断当前语种 -->
<template>
  <div class="index">
    <header class="header header1 clearfix"
            id="header">
      <top-nav />
    </header>
    <main id="main">
      <router-view />
    </main>
    <template>
      <footer-content></footer-content>
    </template>
  </div>
</template>

<script>
import FooterContent from '@/components/common/footerContent.vue'
import TopNav from '@/components/common/topNav.vue'
export default {
  props: [],
  data () {
    return {
    }
  },
  name: "videoPLay",
  components: { TopNav, FooterContent },
  mounted () {
    this.setLang()
  },
  methods: {
    setLang () {
      if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'zh')
      }
      // console.log('当前路由', this.$router.history.current.matched[0].replace('/',''))
      var langStr = (navigator.language || navigator.browserLanguage).toLowerCase();
      console.log(langStr)
      // if (langStr.indexOf('zh') != -1) {
      //     console.log("当前语言环境为中文")
      //     localStorage.setItem('lang',"zh");
      // }else if (langStr.indexOf('en') != -1) {
      //     console.log("当前语言环境为英文")
      //     localStorage.setItem('lang',"en");
      // }else if (langStr.indexOf('es') != -1) {
      //     console.log("当前语言环境为西班牙文")
      //     localStorage.setItem('lang',"es");
      // }else {
      //     console.error("当前语言环境没有对应的翻译版本：" + langStr)
      // }
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
#main {
  margin-top: 155px;
}

@media screen and (max-width: 750px) {
  #main {
    margin-top: 130px;
  }
}

.header {
  height: 45px;
}
</style>
