<template>
  <div class="detail">
    <!-- <div class="text">
        {{dataList.title}}
    </div> -->
    <div class="detail-item">
        <!-- <div class="author">作者：{{dataList.author}}</div> -->
        <div id="details"></div>
    </div>
  </div>
</template>
<script type="text/javascript" src="/wangEditor.min.js"></script>
<script type="text/javascript">
  var E = window.wangEditor
  var editor = new E('#div1')
  editor.create()

  document.getElementById('btn1').addEventListener('click', function () {
    // 读取 html
    alert(editor.txt.html())
  }, false)

  document.getElementById('btn2').addEventListener('click', function () {
    // 读取 text
    alert(editor.txt.text())
  }, false)

</script>
<script>
export default {
  props: [],
  data() {
    return {
      dataList: {},
      details: '',
      langtype:0
    }
  },
  name: "solution",
  components: {},
  created() {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
  },

  watch: {
    '$route.query.id': {
        handler(newVal,oldVal){
          //判断newVal有没有值监听路由变化
          if(newVal!==oldVal) {
            this.getList(this.$route.query.id)
          }
      },
      deep: true
    }
  },

  mounted() {
    this.getList(this.$route.query.id)
  },
  methods: {
    async getList(id) {
      let res = await this.api.get(this.apiPath.dynamicDetail+`${id}`+'/detail',{});
      this.dataList = res
      console.log(res)
      document.getElementById('details').innerHTML = res.detailses?JSON.parse(res.detailses)[this.langtype]:''
    }
  },
  
}
</script>
  
<style lang="less" scoped>
.detail {
  // width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  .text {
    font-size: 22px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }
  .date {
    font-size: 22px;
    color: #999;
    font-family: arial;
    margin-bottom: 6px;
    text-align: center;
  }
  .author {
    font-size: 14px;
    color: #999;
    font-family: arial;
    margin-bottom: 36px;
    text-align: center;
  }
}
.inmain {
  .box {
    .span {
      font-size: 20px;
      color: #7f0114;
    }
    .bts {
      font-size: 35px;
      font-weight: bold;
      color: #333;
    }
    .fadeInUp {
      text-align: center;
    }
  }
}
</style>
